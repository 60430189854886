@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  background: #f2f5fa;
}

.recharts-default-legend{
  font-size: 9px !important;
}
.recharts-surface, .recharts-legend-item-text{
  left: -25px;
  position: relative;
}

.recharts-layer{
  font-size: 9px;
}

.barchart-title {
  padding: 15px;
  color: #191818;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: 600;
}

.filter-br {
  background: #ffffff;
  padding: 3px;
  border: 2px solid #d1d1d1;
  border-radius: 3px;
}


/* styles.css */
.dynamic-border {
  position: relative;
  display: inline-block; /* Ensures the pseudo-element is correctly positioned */
  --border-color: red;
  --border-width: 2px;
  --cover-range: 15%;
}

.dynamic-border::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255); /* Default border width and color */
  clip-path: inset(0 0 var(--cover-range) 0);
  box-sizing: border-box;
  pointer-events: none; /* Ensure the pseudo-element doesn't interfere with interactions */
}



/* tooltip for sidenav */
.li-parent svg {
  fill: #414b5a;
}

.tooltip-arrow {
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid #4a5568;
  border-bottom: 5px solid transparent;
}

.tooltip-text {
  display: block;
  white-space: nowrap
}

.active .short-nav {
  background: red !important;
}

li:hover .short-nav {
  background: rgba(80, 70, 70, 0.445);
}

.short-nav {
  height: 40px;
  width: 5px;
  background: transparent;
  border-radius: 0 15px 15px 0;
  margin-right: 15px;
}

li.active {
  background: rgba(0, 0, 0, 0.03);
  color: red
}

li.active svg {
  fill: red !important;
}

li:hover svg {
  fill: rgb(80, 70, 70);
}

.sublinks-container {
  position: absolute;
  top: 1%;
  left: 65px;
  background-color: #4a5568;
  border: 1px solid #4a5568;
  padding: 5px;
  z-index: 999;
  color: #fff;
}

.sublink {
  margin-top: 5px;
}

.sublink a {
  text-decoration: none;
  white-space: nowrap
}

.sublink a:hover {
  text-decoration: underline;
  white-space: nowrap
}

.li-child {
  display: none;
}

.li-parent:hover>.li-child {
  display: block;
}

.li-parent .icon {
  font-size: 20px;
}









.MuiAutocomplete-inputRoot::before,
.css-1rrfrxi::before {
  border-bottom: 1px solid rgb(0 0 0 / 0%) !important;
}





.test-env-message {
  background: red;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  padding: 5px;
  position: fixed;
  z-index: 9999;
  top: 0;
  width: 100%;
}



































.horizontal-scroll-bar {
  overflow-x: scroll;
}

.horizontal-scroll-bar::-webkit-scrollbar {
  display: block;
  height: 5px;
}

.horizontal-scroll-bar:hover::-webkit-scrollbar {
  display: block;
  height: 5px;
  border-radius: 50px;
}

.horizontal-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 50px;
}

.horizontal-scroll-bar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 50px;
}

.table-scroll-bar::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.table-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
}

.table-scroll-bar::-webkit-scrollbar-track {
  background-color: #fff;
}

.hover-arrow-active:hover {
  background: #fafafaf7;
  box-shadow: 0px 0px 0px 10px #fafafaf7;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
}

.ease-in-out:hover {
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Nav-area styling */
.nav-area {
  width: 105px;
  position: fixed;
  z-index: 9990;
  overflow-y: scroll;
}

.nav-area::-webkit-scrollbar {
  display: block;
  /* Width of the scrollbar */
  width: 1px;
}

.nav-area:hover::-webkit-scrollbar {
  display: block;
  width: 1px;
  /* Width of the scrollbar */
}

.nav-area::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  /* Color of the thumb (handle) */
}

.nav-area::-webkit-scrollbar-track {
  background-color: #dfe4e9;
  /* Color of the track (background) */
}

/* .nav-area a.active, .nav-area span.active {
  background-color: #f3f3f324;
} */
.nav-area a.active,
.nav-area span.active {
  /* background-color: #e1e1e172;
  border-right: 4px solid #e64753; */
  border-radius: 3px;
}

.nav-area a.active label,
.nav-area span.active label {
  color: red;
}

.nav-area a.active label,
.nav-area span.active label {
  font-weight: 700 !important;
}

.nav-area a.active>div>svg,
.nav-area span.active>div>svg {
  background: #F93E4C;
}

.nav-area a,
.nav-area span {
  /* background-color: #f9fafb; */
  color: #6a6a6a;
}

.nav-area a:hover,
.nav-area span:hover {
  background-color: #f9fafb7d;
}

.nav-area a>div {
  border-radius: 10px;
  margin-bottom: 5px;
}

.nav-area a.active>div>svg,
.nav-area span.active>div>svg {
  fill: #fff;
}

.nav-area a>div>svg,
.nav-area span>div>svg {
  height: 60px;
  fill: #363131;
  padding: 20px;
  background: #f6f6f6;
  border-radius: 10px;
}

.nav-area a label,
.nav-area span label {
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
  color: #1E1E1E;
  text-transform: capitalize;
}

.nav-forms {
  background: rgba(0, 0, 0, 0.52);
  width: 100%;
  left: 106px;
  bottom: 0;
  top: 0;
  z-index: 1050;
  background: #00000059;
}

.nav-forms div>ul {
  padding-top: 95px;
  width: 250px;
  /* background: #fff; */
  height: 100vh;
}

.nav-forms::-webkit-scrollbar {
  display: none;
  /* Width of the scrollbar */
  width: 5px;
}

.nav-forms:hover::-webkit-scrollbar {
  display: block;
  width: 5px;
  /* Width of the scrollbar */
}

.nav-forms::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  /* Color of the thumb (handle) */
}

.nav-forms::-webkit-scrollbar-track {
  background-color: #dfe4e9;
  /* Color of the track (background) */
}

td a, .notification-link a {
  color: blue;
}

.beamer_icon.active, #beamerIcon.active {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -7px;
  color: #fff;
  font-family: arial;
  text-align: center;
  font-weight: 700;
  opacity: 1;
  letter-spacing: 0;
  animation: beamer_bounce 1.5s linear infinite;
  right: -12px;
}

.beamer_icon,
#beamerIcon {
  display: none;
  position: relative;
  -webkit-font-smoothing: antialiased;
  opacity: 0;
  -webkit-transition: opacity .5s ease-in;
  -o-transition: opacity .5s ease-in;
  transition: opacity .5s ease-in;
}

@keyframes beamer_bounce {

  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.logo-fixed {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 103px;
  /* border-bottom: 1px solid #d6d6d6; */
  /* padding: 10px 10px 15px 10px; */
}

.logo-fixed>img {
  max-width: 95px !important;
  margin-top: 15px;
}

.ease-out {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition: all .3s;
}

/* Header styling */
.header-fixed-area {
  left: 105px;
  background: #f2f5fa;
  z-index: 1000;
  border-bottom: 1px solid #e9effb;
}

svg.bell.active>path {
  stroke: black;
}

.new-message-count {
  position: absolute;
  top: 3px;
  background: #EFD167;
  padding: 1px 6px;
  border-radius: 13px;
  font-size: 11px;
  /* right: -9px; */
  left: 7px;
  font-weight: 700;
}

/* Mian body styling  #f8f8f8;*/
.main-container {
  background: #f2f5fa;
  width: calc(100% - 105px);
  margin-left: 105px;
}


/* form-lists */
.available-forms {
  height: 85vh;
  position: sticky;
  top: 62px;
  overflow-y: scroll;
}

.available-forms::-webkit-scrollbar {
  display: block;
  width: 1px;
  /* Width of the scrollbar */
}

.available-forms ul li a.active {
  background: #2E5892;
  font-weight: 700;
  color: #fff;
}


/* settings Table custom */
.paid-label {
  width: 65px;
}

.rounded-pin-image {
  background: #F9FAFB;
  /* padding: 7px 14px; */
  border-radius: 50%;
  position: relative;
  margin-left: -6px;
  border: 1px solid #fff
}

.rounded-pin-plus-text {
  background: #F9FAFB;
  border-radius: 50%;
  position: relative;
  margin-left: -6px;
  border: 1px solid #fff;
  padding: 7px 8px;
  font-size: 12px;
  font-weight: 500;
}



/* checkbox */
.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.square label {
  border-radius: 0 !important;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

/* .round input[type="checkbox"]:checked + label {
  background-color: #1616b5;
  border: 1px solid #0e6fd7;
} */

.round input[type="checkbox"]:checked+label {
  background-color: #ff3746;
  border: 1px solid #ff9b9b;
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}


/* radio */
.radio label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.radio label:after {
  border: 6px solid #7F56D9;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 0px;
  border-radius: 50%;
}

.radio input[type="radio"] {
  visibility: hidden;
}

.radio input[type="radio"]:checked+label {
  background-color: #fff;
  border: 1px solid #7F56D9
}

.radio input[type="radio"]:checked+label:after {
  opacity: 1;
}



/* sweet alert custom */
.swal2-popup.swal2-modal.swal2-show {width: auto !important;}
.swal2-popup.swal2-modal.swal2-icon-error.swal2-show,
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show {width: 32em !important;}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #069906 !important;
  color: #fff;
  font-size: 1em;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgb(0 127 255 / 50%) !important;
}


/* policy styling */
.policy-scroll-bar::-webkit-scrollbar {
  display: block;
  width: 7px;
  /* Width of the scrollbar */
}

.policy-scroll-bar:hover::-webkit-scrollbar {
  display: block;
  width: 7px;
  /* Width of the scrollbar */
}

.policy-scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgb(32, 30, 30);
  /* Color of the thumb (handle) */
  border-radius: 35px;
}

.policy-scroll-bar::-webkit-scrollbar-track {
  background-color: #d6d6d6;
  /* Color of the track (background) */
  border-radius: 15px;
}

.span-input {
  background: #fbfbfb;
  cursor: not-allowed;
}

input,
select,
textarea,
.span-input {
  border: 1px solid #cbcbcb;
}

input:focus,
select:focus,
textarea:focus {
  /* border-color: #2684FF;
  box-shadow: 0 0 0 1px #2684FF; */
  outline: none;
}

.input {
  font-weight: 600;
  min-height: 38px !important;
  height: 38px !important;
  font-size: 14px;
  border-color: #cbcbcb;
  border-radius: 2px !important;
}

.mu-input input {
  min-height: 20px !important;
  height: 20px !important;
  font-size: 14px;
  border-color: #cbcbcb;
  border-radius: 2px !important;
}

input:disabled,
select:disabled {
  cursor: not-allowed;
}

.css-13cymwt-control {
  font-weight: 600;
  min-height: 38px !important;
  height: 38px !important;
  font-size: 14px;
  overflow: hidden !important;
  border-color: #cbcbcb;
  border-radius: 2px !important;
}

.css-13cymwt-control {
  /* height: 34px !important;
  font-size: 12px; */
  overflow: hidden !important;
  border-color: #cbcbcb;
  border-radius: 2px !important;
}

.css-13cymwt-control:hover {
  border-radius: 2px !important;
}

.css-1jqq78o-placeholder {
  color: hsl(0deg 0% 50% / 51%) !important;
}

.css-qbdosj-Input input:focus,
.css-qbdosj-Input select:focus {
  border-color: #ffffff !important;
  box-shadow: 0 0 0 1px #ffffff !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container input {
  width: 100%;
  border-radius: 2px !important;
}

button:disabled {
  background: #ededed !important;
  color: #898989;
  border: 1px solid #898989;
}

.form-description ul li {
  list-style: disc;
  padding-left: 2px;
  margin-left: 25px;
}

.form-description ol li {
  list-style: auto;
  padding-left: 2px;
  margin-left: 25px;
}

.attach-result {
  height: 320px;
  overflow-y: scroll;
}

/* .Toastify__toast-container--top-right {
  top: 5.2em !important;
  right: 1.1em !important;
} */
.dropzoneStyle {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 50px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.thumbnailStyle {
  max-width: 100px;
  max-height: 100px;
  margin: 10px 0;
}

.css-1wuilmg-MuiAutocomplete-root,
.MuiAutocomplete-root {
  width: 100% !important;
  padding: 5px 12px 0px 12px !important;
  border: 1px solid #cbcbcb !important;
}

.css-1h51icj-MuiAutocomplete-root,
.MuiAutocomplete-root {
  border: 1px solid #cbcbcb !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:before,
.MuiInputBase-root-MuiInput-root:before {
  border-bottom: none !important;
}

.MuiInput-root .MuiInput-input {
  padding: 3px 4px 7px 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: 'Nunito', sans-serif !important;
}

.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator,
.MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  border-left: 1px solid #cccccc !important;
  border-radius: 0 !important;
}

/* Style The Dropdown Button */
.dropbtn {
  padding: 6px 4px 6px 12px;
  color: #1E1E1E;
  border-radius: 6px;
  font-size: 12px;
  transition: .5s ease;
  vertical-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 130px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* right: 0; */
}

/* Links inside the dropdown */
.dropdown-content button {
  color: black;
  padding: 12px;
  display: block;
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 12px;
  text-align: left;
  /* text-wrap: nowrap; */
  /* border-bottom: 1px solid #d9d9d9; */
}

/* Change color of dropdown links on hover */
.dropdown-content button:hover {
  background-color: #F93E4C;
  color: white
}

.dropdown-content button:disabled {
  background-color: whitesmoke;
  color: grey
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: #2E5892
}

.red-button {
  color: white;
  background-color: #F93E4C;
}

.ql-editor {
  min-height: 200px !important;
}

input:disabled,
textarea:disabled {
  background-color: #f2f2f2;
  border-color: #e6e6e6;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important;
}

.Toastify__toast-body {
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important;
}

.Toastify__toast-icon {
  margin-inline-end: 20px !important;
}


/* Set scrollbar to dark mode */
/* @media (prefers-color-scheme: dark) {
  .nav-area::-webkit-scrollbar-thumb {
    background-color: #919498;
  }

  .nav-area::-webkit-scrollbar-track {
    background-color: #414b5a;
  }

  .css-13cymwt-control {
    background: #1f2937 !important;
  }

  .css-1nmdiq5-menu {
    background: #1f2937 !important;

  }


  .nav-area a.active,
  .nav-area span.active {
    background-color: #1c2b41;
  }

  .nav-area a.active label,
  .nav-area span.active label {
    color: rgb(199, 199, 199);
  }

  .nav-area a.active>div>svg,
  .nav-area span.active>div>svg {
    background: #162c51;
  }

  .nav-area a,
  .nav-area span {
    color: #d9d9d9;
  }

  .nav-area a:hover,
  .nav-area span:hover {
    background-color: #1c2b41;
  }

  .nav-area a.active>div>svg,
  .nav-area span.active>div>svg {
    fill: #fff;
  }

  .nav-area a>div>svg,
  .nav-area span>div>svg {
    fill: #1E1E1E;
    background: #a8a8a8;
  }

  .nav-area a label,
  .nav-area span label {
    color: #e6e6e6;
  }

  .nav-forms {
    background: rgba(0, 0, 0, 0.52);
    background: #fff;
  }

  body {
    background: #000;
  }
} */

@media screen and (max-width: 400px) {
  .main-container {
    background: #f2f5fa;
    width: 100%;
    margin-left: 0px;
  }

  .header-fixed-area {
    left: 0px;
    background: #f2f5fa;
    z-index: 1000;
  }
}
